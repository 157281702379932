import React from 'react';
import RotatingComponents from '../components/RotatingComponents';
import AutoRefresh from '../components/AutoRefreshProps';
import DeltaHotelData from '../content/delta-hotel.json';
import {
  LogoParkeo,
  LogoParkeoFavicon,
  LogoDeltaBlanc,
  LogoEnterprise,
} from '../img';

// Définir les écrans ici
function RateScreen() {
  return (
    <div
      className="flex min-h-screen flex-col justify-between"
      style={{ backgroundColor: '#121B4D' }}
    >
      <header
        className="relative border-b-2 p-[4vw]"
        style={{ backgroundColor: '#fff', borderColor: '#000' }}
      >
        <div className="items-right flex items-center justify-between">
          <img
            src={LogoParkeo}
            alt="JPark Logo"
            className="max-h-[9.6vh] min-w-[30vw] max-w-[36vw] object-contain"
          />
          <div
            className="h-[4vh] min-h-[] w-[20px] rounded-lg"
            style={{ backgroundColor: '#8FBA02' }}
          ></div>
          <h2
            className="text-[6vw] font-extrabold"
            style={{ color: '#121B4D' }}
          >
            Hôtel Delta
          </h2>
        </div>
      </header>

      <div
        className="flex flex-grow flex-col justify-start px-[4vw] py-[2vh]"
        style={{ backgroundColor: '#121B4D', color: 'white' }}
      >
        <div className="mb-[4vh] flex justify-between">
          <div className="flex flex-col">
            <h2 className="mb-[1vh] text-[7vw] font-extrabold">
              {DeltaHotelData.dayFr.title}
            </h2>
            <p
              className="text text-[6vw] font-extrabold leading-none"
              style={{ color: '#8FBA02' }}
            >
              {DeltaHotelData.dayEn.title}
            </p>
          </div>
        </div>

        <div className="border-t-[8px]" style={{ borderColor: '#8FBA02' }}>
          <div
            className="flex justify-between border-b-[8px] py-[2vh]"
            style={{ borderColor: '#8FBA02' }}
          >
            <div className="flex max-w-[55%] flex-col justify-center">
              <p className="mb-[1vh] text-[4vw] font-bold leading-[1.2]">
                {DeltaHotelData.dayFr.line1Title}
              </p>
              <p
                className="text-[3.4vw] font-bold leading-[1.2]"
                style={{ color: '#8FBA02' }}
              >
                {DeltaHotelData.dayEn.line1Title}
              </p>
            </div>
            <div className="flex max-w-[45%] flex-col justify-center text-right">
              <p
                className="flex flex-col justify-center text-[5vw] font-extrabold"
                style={{ color: '#fff' }}
              >
                {DeltaHotelData.dayFr.line1Price}
              </p>
              <p
                className="flex flex-col justify-center text-[4vw] font-extrabold"
                style={{ color: '#fff' }}
              >
                {DeltaHotelData.dayFr.line1Desc}
              </p>
            </div>
          </div>

          <div
            className="flex justify-between border-b-[8px] py-[2vh]"
            style={{ borderColor: '#8FBA02' }}
          >
            <div className="flex max-w-[55%] flex-col justify-center">
              <p className="mb-[1vh] text-[4vw] font-bold leading-[1.2]">
                {DeltaHotelData.dayFr.line2Title}
              </p>
              <p
                className="text-[3.4vw] font-bold leading-[1.2]"
                style={{ color: '#8FBA02' }}
              >
                {DeltaHotelData.dayEn.line2Title}
              </p>
            </div>
            <div className="flex max-w-[40%] flex-col justify-center text-right">
              <p
                className="flex flex-col justify-center text-[5vw] font-extrabold"
                style={{ color: '#fff' }}
              >
                {DeltaHotelData.dayFr.line2Price}
              </p>
              <p
                className="flex flex-col justify-center text-[4vw] font-extrabold"
                style={{ color: '#fff' }}
              >
                {DeltaHotelData.dayFr.line2Desc}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-[6vh] flex flex-col items-center">
        <p
          className="flex flex-col justify-center text-[2.6vh] font-extrabold"
          style={{ color: '#fff' }}
        >
          {DeltaHotelData.ScreenTextFr.thankForVisit}
        </p>
        <p
          className="text-[2vh] font-bold leading-[1.2]"
          style={{ color: '#8FBA02' }}
        >
          {DeltaHotelData.ScreenTextEn.thankForVisit}
        </p>
      </div>

      <footer
        className="flex items-center justify-between px-[4vw] py-8"
        style={{ color: '#fff', backgroundColor: '#8FBA02' }}
      >
        <div className="flex flex-col justify-center">
          <p className="text-[6vw] font-extrabold" style={{ color: '#121B4D' }}>
            418 440-4638
          </p>
          <p className="text-[4vw] font-bold" style={{ color: '#121B4D' }}>
            www.parkeo.ca
          </p>
        </div>
        <img src={LogoParkeoFavicon} alt="Logo Parkeo" className="h-[6vh]" />
      </footer>
    </div>
  );
}

function TextScreen() {
  return (
    <div
      className="flex min-h-screen flex-col justify-between"
      style={{ backgroundColor: '#121B4D' }}
    >
      <header
        className="relative border-b-2 p-[4vw]"
        style={{ borderColor: '#000', backgroundColor: '#fff' }}
      >
        <div className="items-right flex items-center justify-between">
          <img
            src={LogoParkeo}
            alt="JPark Logo"
            className="max-h-[9.6vh] min-w-[30vw] max-w-[36vw] object-contain"
          />
          <div
            className="h-[3vh] w-[20px] rounded-lg"
            style={{ backgroundColor: '#8FBA02' }}
          ></div>
          <h2
            className="text-[6vw] font-extrabold"
            style={{ color: '#121B4D' }}
          >
            Hôtel Delta
          </h2>
        </div>
      </header>

      <div
        className="flex flex-grow flex-col justify-start px-[4vw] py-[2vh]"
        style={{ backgroundColor: '#121B4D', color: '#fff' }}
      >
        <div className="mb-[4vh] flex justify-between">
          <h2 className="mb-[1vh] text-[7vw] font-extrabold">
            {DeltaHotelData.ScreenTextFr.important}
          </h2>
          <img src={LogoDeltaBlanc} alt="Logo Delta" className="h-[15vh]" />
        </div>

        <div className="mt-[-11vh] flex flex-1 flex-col justify-between">
          <div className="mb-[2vh] flex flex-col">
            <h2 className="mb-4 text-[5vw] font-extrabold">
              {DeltaHotelData.ScreenTextFr.hotelGuest}
            </h2>
            <h3
              className="text-[4vw] font-extrabold"
              style={{ color: '#8FBA02' }}
            >
              {DeltaHotelData.ScreenTextEn.hotelGuest}
            </h3>
          </div>

          <p className="text-[4vw] font-bold">
            {DeltaHotelData.ScreenTextFr.beforePaying} /{' '}
            <span className="text-[3.3vw]" style={{ color: '#8FBA02' }}>
              {DeltaHotelData.ScreenTextEn.beforePaying}
            </span>
          </p>

          <div className="flex flex-col">
            <p className="text-[4vw] font-bold">
              {DeltaHotelData.ScreenTextFr.if}
              <span className="underline">
                {DeltaHotelData.ScreenTextFr.include}
              </span>
              {DeltaHotelData.ScreenTextFr.pressOn}
              <span className="underline">
                {DeltaHotelData.ScreenTextFr.ticket}.
              </span>
            </p>
            <p className="text-[3.3vw] font-bold" style={{ color: '#8FBA02' }}>
              {DeltaHotelData.ScreenTextEn.ifInclude}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[4vw] font-bold">
              {DeltaHotelData.ScreenTextFr.ifNot}
            </p>
            <p className="text-[3.3vw] font-bold" style={{ color: '#8FBA02' }}>
              {DeltaHotelData.ScreenTextEn.ifNot}
            </p>
          </div>

          <div className="mb-5 mt-[3vh]">
            <img
              src={LogoEnterprise}
              alt="Logo Enterprise"
              className="h-[6vh] object-contain"
            />
          </div>

          <div className="flex flex-col">
            <p className="text-[4vw] font-bold">
              {DeltaHotelData.ScreenTextFr.enterprise}
            </p>
            <p className="text-[3.3vw] font-bold" style={{ color: '#8FBA02' }}>
              {DeltaHotelData.ScreenTextEn.enterprise}
            </p>
          </div>
        </div>
      </div>

      <footer
        className="flex items-center justify-between px-[4vw] py-8"
        style={{ color: '#fff', backgroundColor: '#8FBA02' }}
      >
        <div className="flex flex-col justify-center">
          <p
            className="text-[6vw] font-extrabold"
            style={{ color: '#121B4D', backgroundColor: '#8FBA02' }}
          >
            418 440-4638
          </p>
          <p className="text-[4vw] font-bold" style={{ color: '#121B4D' }}>
            www.parkeo.ca
          </p>
        </div>
        <img src={LogoParkeoFavicon} alt="Logo Parkeo" className="h-[6vh]" />
      </footer>
    </div>
  );
}

function DeltaHotel() {
  // Définir les écrans à alterner
  const screens = [RateScreen, TextScreen];

  return (
    <>
      <AutoRefresh intervalms={900000} />
      <RotatingComponents screens={screens} interval={5500} />
    </>
  );
}

export default DeltaHotel;
